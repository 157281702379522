import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#5d6a72',
            main: '#35454f',
            dark: '#253037',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffd733',
            main: '#ffce01',
            dark: '#b29000',
            contrastText: '#000',
        },
    },
});

function withRoot(Component) {
    function WithRoot(props) {
        // MuiThemeProvider makes the theme available down the React tree
        // thanks to React context.
        // CssBaseline kickstart an elegant, consistent, and simple baseline to build upon.
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Component {...props} />
            </MuiThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;
