import ReactGA from 'react-ga';

const GA_PROD_TRACKING_ID = 'UA-128517267-1';
const GA_DEV_TRACKING_ID = 'UA-128517267-2';

/**
 * Depending on the NODE_ENV, init the ReactGA client with production/development ID
 * and with/without test mode on.
 * - production: use prod id, test mode off
 * - development: use dev id, test mode off
 * - test: use dev id, test mode on
 */
export function initGA() {
    const NODE_ENV = process.env.NODE_ENV;
    const options = {
        titleCase: false,
    };
    if (NODE_ENV === 'production') {
        ReactGA.initialize(GA_PROD_TRACKING_ID, options);
    } else {
        if (NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('React-GA initialized in dev env:', GA_DEV_TRACKING_ID, options);
        }
        options.testMode = NODE_ENV === 'test'; // automatically detect if it's currently in test env
        ReactGA.initialize(GA_DEV_TRACKING_ID, options);
    }
}

/**
 * Report to GA about the current pageview.
 * @param {String} page the pathname of the current page
 */
export function trackPageView(page) {
    ReactGA.pageview(page);
}

/**
 * Report to GA about the page to navigate to.
 * @param {String} targetPath the pathname of the destination page
 */
export function trackNavigation(targetPath) {
    ReactGA.event({
        category: 'Navigation',
        action: `Navigate to ${targetPath}`,
    });
}

/**
 * Report to GA about the search term.
 * @param {String} searchTerm the query term
 */
export function trackSearch(searchTerm) {
    ReactGA.event({
        category: 'Search',
        action: `Search for ${searchTerm}`,
        label: searchTerm,
    });
}

/**
 * Report to GA about the external page to redirect to.
 * @param {String} url the URL of the destination site
 */
export function trackRedirect(url) {
    ReactGA.event({
        category: 'Redirect',
        action: `Redirect to ${url}`,
    });
}

/**
 * Report to GA about the download action.
 * @param {String} url the URL of the download link
 */
export function trackDownload(url) {
    ReactGA.event({
        category: 'Download',
        action: `Download ${url}`,
    });
}

/**
 * Report to GA about the mailto action. For now, ReactGA doesn't allow email
 * address to appear in action. It doesn't affect the implementation, but
 * callers should be aware.
 * @param {String} recipient the recipient of the mailto action
 */
export function trackEmail(recipient) {
    ReactGA.event({
        category: 'Email',
        action: `Send email to ${recipient}`,
    });
}

/**
 * Report to GA about the error.
 * @param {string} description the error message
 * @param {boolean} isFatal if the error is fatal
 */
export function trackError(description, isFatal = false) {
    ReactGA.exception({
        description,
        fatal: isFatal,
    });
}

/**
 * A miss is that when the user enters a valid query but no result is found.
 * The incentive is to track uncovered data and improve in the future.
 * @param {string} description a descriptive message about the expected but missing data
 */
export function trackMiss(description) {
    ReactGA.event({
        category: 'Miss',
        action: description,
        nonInteraction: true,
    });
}

/**
 * Report to GA about the NLP usage.
 * @param {String} key the keyword used for the NLP process
 */
export function trackNLP(key) {
    ReactGA.event({
        category: 'NLP',
        action: `Run NLP on ${key}`,
        label: key,
    });
}

/**
 * Report to GA about user navigation to source sites.
 * @param {String} siteName name of the data source
 * @param {String} queryTerm the current query the user is running
 * @param {String} link the link the user navigates to
 */
export function trackSourceView(siteName, queryTerm, link) {
    ReactGA.event({
        category: 'View Source',
        action: `Navigate to ${siteName} for ${queryTerm}`,
        label: link,
    });
}

/**
 * Report to GA about user navigation to a different page on the current query.
 * @param {String} queryTerm
 * @param {Number} newPageNumber
 */
export function trackPageChange(queryTerm, newPageNumber) {
    ReactGA.event({
        category: 'Page Change',
        action: `Navigate to page ${newPageNumber} for ${queryTerm}`,
    });
}
