import { useEffect } from 'react';
import { AppBar, Divider, makeStyles, Toolbar, Typography } from '@material-ui/core';
import logo from './omni_logo.png';
import withRoot from './withRoot';
import { trackRedirect, trackDownload, trackEmail, trackPageView } from './utils/ReactGA';

const URL_NCER_PAPER = 'https://www.nature.com/articles/s41467-019-13212-3';
const URL_OMNI_PAPER = 'https://doi.org/10.1101/600098';
const URL_NCER_DATA_V1_10BP = 'https://telentilab-dataset.s3.amazonaws.com/ncER/v1/ncER_10bpBins_percentile_version1.txt.gz';
const URL_NCER_DATA_V2_10BP = 'https://telentilab-dataset.s3.amazonaws.com/ncER/v2/Bin_10bp/ncER_10bpBins_allChr_coordSorted.txt.gz';
const URL_NCER_DATA_V2_1BP = (chr) => `https://telentilab-dataset.s3.amazonaws.com/ncER/v2/Bin_1bp/ncER_perc_chr${chr}_coordSorted.txt.gz`;
const URL_NCER_GITHUB = 'https://github.com/TelentiLab/ncER_datasets';
const EMAIL = 'omni@telentilab.com';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    appBar: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
    },
    logo: {
        width: 64,
        height: 'auto',
    },
    title: {
        margin: theme.spacing(1),
    },
    content: {
        [theme.breakpoints.up('sm')]: {
            width: '70%',
        },
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
        padding: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
}));

const App = () => {
    const classes = useStyles();

    useEffect(() => {
        trackPageView('/goodbye');
    });

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position='static' color='default'>
                <Toolbar>
                    <img className={classes.logo} src={logo} alt='Omni logo' />
                </Toolbar>
            </AppBar>
            <div className={classes.title}>
                <Typography variant='h6' color='primary'>
                    Announcement
                </Typography>
                <Divider />
            </div>
            <div className={classes.content}>
                <Typography variant='body2' paragraph>
                    We are sorry to inform you that Omni will no longer be maintained. However, we will still be hosting our own dataset, <b>ncER</b>, here for download.
                </Typography>
            </div>
            <div className={classes.title}>
                <Typography variant='h6' color='primary'>
                    ncER
                </Typography>
                <Divider />
            </div>
            <div className={classes.content}>
                <Typography variant='body2' paragraph>
                    The non-coding Essential Regulation (ncER) score is a resource developed by The Scripps Research Translational Institute together with researchers from UC San Diego and Los Angeles, and Stanford, with the goal of providing
                    precomputed analytics to all nucleotides in the human genome. The metric is trained primarily on non-coding genome features and on non-coding pathogenic variants. It should not be used for the analysis of the protein coding
                    regions.
                </Typography>
                <Typography variant='body2' paragraph>
                    ncER scores identify the most essential sequence segments in the non-coding genome to support the mapping of regulatory regions of critical function. It serves also to prioritize regulatory genetic variants in the investigation of
                    rare diseases. The scores are presented as percentiles. Suggested percentile thresholds indicative of essentiality are: 95%, 99%, 99.5%, 99.9%.
                </Typography>
                <Typography variant='body2' paragraph>
                    All data here are released for the benefit of the wider biomedical community, without restriction on use.
                </Typography>
                <Typography variant='body2'>Please refer to this work as:</Typography>
                <Typography variant='caption'>
                    <ul>
                        <li>
                            <a href={URL_NCER_PAPER} target='_blank' rel='noopener noreferrer' onClick={() => trackRedirect(URL_NCER_PAPER)}>
                                <b>Ranking of non-coding pathogenic variants and putative essential regions of the human genome</b>
                                {` (${URL_NCER_PAPER})`}
                            </a>
                        </li>
                        <li>
                            <a href={URL_OMNI_PAPER} target='_blank' rel='noopener noreferrer' onClick={() => trackRedirect(URL_OMNI_PAPER)}>
                                <b>Retrieval of whole human genome clinical variant information through search motors</b>
                                {` (${URL_OMNI_PAPER})`}
                            </a>
                        </li>
                    </ul>
                </Typography>
                <Typography variant='body1'>Download</Typography>
                <ul>
                    <li>
                        <Typography variant='body2'>
                            <b>version 1</b> contains the genome-wide percentiles (10bp bins) of the ncER scores created on August 13th 2018. The coordinates are mapped to hg19.
                        </Typography>
                        <ul>
                            <li>
                                <a href={URL_NCER_DATA_V1_10BP} target='_blank' rel='noopener noreferrer' onClick={() => trackDownload(URL_NCER_DATA_V1_10BP)}>
                                    {'10bp'}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Typography variant='body2'>
                            <b>version 2</b> is available in two formats:
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant='body2'>Bin_10bp: 1 file with the 10bp resolution genome-wide percentile of the ncER scores created on March 6th 2019. The coordinates are mapped to hg19.</Typography>
                                <ul>
                                    <li>
                                        <a href={URL_NCER_DATA_V2_10BP} target='_blank' rel='noopener noreferrer' onClick={() => trackDownload(URL_NCER_DATA_V2_10BP)}>
                                            {'10bp'}
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <Typography variant='body2'>Bin_1bp: 1 file per chromosome, with the 1bp resolution genome-wide percentile of the ncER scores created on March 6th 2019. The coordinates are mapped to hg19. </Typography>
                                <ul>
                                    {Array.from(Array(23).keys()).map((i) => {
                                        const chromosome = i === 22 ? 'X' : `${i + 1}`;
                                        const url = URL_NCER_DATA_V2_1BP(chromosome);
                                        return (
                                            <li key={`v2-1bp-chrom-${chromosome}`}>
                                                <a href={url} target='_blank' rel='noopener noreferrer' onClick={() => trackDownload(url)}>
                                                    {`1bp - chromosome ${chromosome}`}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <Typography variant='body2'>
                    {'Alternatively, visit the GitHub repo for ncER data: '}
                    <a href={URL_NCER_GITHUB} target='_blank' rel='noopener noreferrer' onClick={() => trackRedirect(URL_NCER_GITHUB)}>
                        {URL_NCER_GITHUB}
                    </a>
                    <br />
                    <br />
                    {`Inquiries to: `}
                    <a href={`mailto:${EMAIL}`} target='_blank' rel='noopener noreferrer' onClick={() => trackEmail(EMAIL)}>
                        {EMAIL}
                    </a>
                </Typography>
            </div>
        </div>
    );
};

export default withRoot(App);
